
function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


require('../css/materialize.css')
require('../css/main.css')


require('../js/materialize.min.js')
require('../js/scroll_detection.js')



require('./page.css')

document.addEventListener('DOMContentLoaded', function() {



    var elems = document.querySelectorAll('.scrollspy');
    var instances = M.ScrollSpy.init(elems);

    var elems = document.querySelectorAll('.dropdown-trigger');
    var instances = M.Dropdown.init(elems);

    var modals = document.querySelectorAll('.modal');
    var modals = M.Modal.init(modals,{
        startingTop:'0%',
        opacity:1,
        endingTop:'0%'
    });

    var elems = document.querySelectorAll('.parallax');
    var instances = M.Parallax.init(elems,{
        responsiveThreshold:700
    });

    var slider = document.querySelectorAll('.slider');
    var slider_instances = M.Slider.init(slider,{
        indicators: false
    });

    var boxes = document.querySelectorAll('.materialboxed');
    var materialboxes = M.Materialbox.init(boxes);
 
    var gallery = document.getElementById("series_gallery")
    var gallery2 = document.getElementById("series_gallery2")

    var gallery_pic= true;
    setInterval(() => {
        if(gallery_pic==true){
            gallery.src="../src/assets/series2new.jpg"
            gallery_pic=false;
        } else {
            gallery.src="../src/assets/series1new.jpg"
            gallery_pic=true;
        }
    }, 5000);

    var gallery_pic2= 0;
    setInterval(() => {
        if(gallery_pic2==0){
            gallery2.src="../src/assets/series_forestnew.jpg"
            gallery_pic2=1;
        } else if(gallery_pic2==1){
            gallery2.src="../src/assets/series_boatnew.jpeg"
            gallery_pic2=2;
        } else if(gallery_pic2==2){
            gallery2.src="../src/assets/series_water.jpg"
            gallery_pic2=0;
        }
    }, 5000);

});
